import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-20 my-10" }
const _hoisted_4 = { class: "fs-2x fw-bolder mb-10" }
const _hoisted_5 = { class: "text-gray-400 fs-5 fw-bold mb-13" }
const _hoisted_6 = { class: "text-center px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("h2", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createVNode("p", _hoisted_5, [
          _createVNode("span", { innerHTML: _ctx.description }, null, 8, ["innerHTML"])
        ]),
        _createVNode("button", {
          type: "button",
          class: "btn btn-primary er fs-6 px-8 py-4",
          "data-bs-toggle": "modal",
          "data-bs-target": `#${_ctx.modalId}`
        }, _toDisplayString(_ctx.buttonText), 9, ["data-bs-target"])
      ]),
      _createVNode("div", _hoisted_6, [
        _createVNode("img", {
          src: _ctx.image,
          alt: "",
          class: "mw-100 mh-300px"
        }, null, 8, ["src"])
      ])
    ])
  ]))
}